<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Requirement</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Requirement</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listCategory"
                  item-text="category"
                  return-object
                  v-model="category"
                  @change="onChangeCategory"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.category"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Start Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="item.startDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.startDate"
                    @input="menuStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>End Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="item.endDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.endDate"
                    @input="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Description</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      flat
                      rows="3"
                      background-color="white"
                      v-model="item.description"
                    ></v-textarea
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="item.amount"
                  persistent-hint
                  :hint="formatPrice(item.amount)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="type === 'settlement' || type === 'other'">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Used Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="item.usedAmount"
                  @input="onInputUsedAmount"
                  persistent-hint
                  :hint="formatPrice(item.usedAmount)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="type === 'settlement' || type === 'other'">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Remain Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <span>{{ formatPrice(item.remainAmount) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="type === 'cost'">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Reff</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-select
                  flat
                  dense
                  outlined
                  background-color="white"
                  :items="listReff"
                  v-model="item.reference"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">
          Close
        </v-btn>
        <v-btn color="red" @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-kasbon",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    type: String,
    listCategory: Array,
  },
  computed: {
    setCategory() {
      return this.listCategory.find(
        x => x.requirementCategoryId === this.item.requirementCategoryId
      );
    },
  },
  watch: {
    setCategory(val) {
      this.category = val;
    },
  },
  data() {
    return {
      listReff: ["PO", "BPO", "Internal"],
      menuStartDate: false,
      menuEndDate: false,
      category: null,
    };
  },
  methods: {
    save() {
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    deleteItem() {
      this.$emit("deleteItem", this.index);
    },
    onInputUsedAmount() {
      this.item.remainAmount = this.item.amount - this.item.usedAmount;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    addItemTemplate() {
      this.description.push({
        text: "",
        value: 0,
      });
    },
    onChangeCategory(val) {
      this.item.requirementCategoryId = val.requirementCategoryId;
      this.item.category = val.category;
      this.item.description = val.description;
      this.item.accountName = val.accountName;
    },
  },
};
</script>

<style></style>
